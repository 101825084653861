import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

const Login = () => import('@/views/pages/Login')

const CategoryList = () => import('@/views/categories/CategoryList')
const CategoryAdd = () => import('@/views/categories/CategoryAdd')
const CategoryEdit = () => import('@/views/categories/CategoryEdit')

const BrandList = () => import('@/views/brands/BrandList')
const BrandEdit = () => import('@/views/brands/BrandEdit')
const BrandAdd = () => import('@/views/brands/BrandAdd')

const ProductList = () => import('@/views/products/ProductList')
const ModerationsList = () => import('@/views/products/ModerationsList')
const ProductEdit = () => import('@/views/products/ProductEdit')
const ProductAdd = () => import('@/views/products/ProductAdd')

const FilterAdd = () => import('@/views/filters/FilterAdd')
const FilterList = () => import('@/views/filters/FilterList')
const FilterEdit = () => import('@/views/filters/FilterEdit')

const SliderList = () => import('@/views/slider/SliderList')
const SliderAdd = () => import('@/views/slider/SliderAdd')
const SliderEdit = () => import('@/views/slider/SliderEdit')

const BannerList = () => import('@/views/banners/BannerList')
const BannerEdit = () => import('@/views/banners/BannerEdit')
const BannerAdd = () => import('@/views/banners/BannerAdd')

const NavBannerList = () => import('@/views/navbanners/NavBannerList')
const NavBannerEdit = () => import('@/views/navbanners/NavBannerEdit')
const NavBannerAdd = () => import('@/views/navbanners/NavBannerAdd')

const OrdersList = () => import('@/views/orders/OrdersList')
const OrderEdit = () => import('@/views/orders/OrderEdit')

const DeliveryDaysList = () => import('@/views/deliveryDays/DeliveryDaysList')
const DeliveryDaysEdit = () => import('@/views/deliveryDays/DeliveryDaysEdit')
const DeliveryDaysAdd = () => import('@/views/deliveryDays/DeliveryDaysAdd')

const PriceList = () => import('@/views/pricelist/PriceList')
const PriceListEdit = () => import('@/views/pricelist/PriceListEdit')
const PriceListAdd = () => import('@/views/pricelist/PriceListAdd')

const TextList = () => import('@/views/text/TextList')
const TextEdit = () => import('@/views/text/TextEdit')
const TextAdd = () => import('@/views/text/TextAdd')

const NewsList = () => import('@/views/news/NewsList')
const NewsEdit = () => import('@/views/news/NewsEdit')
const NewsAdd = () => import('@/views/news/NewsAdd')

const PromoCodeList = () => import('@/views/promocode/PromoCodeList')
const PromoCodeEdit = () => import('@/views/promocode/PromoCodeEdit')
const PromoCodeAdd = () => import('@/views/promocode/PromoCodeAdd')

const PackList = () => import('@/views/packs/PackList')
const PackEdit = () => import('@/views/packs/PackEdit')
const PackAdd = () => import('@/views/packs/PackAdd')

const SolutionList = () => import('@/views/solutions/SolutionList')
const SolutionEdit = () => import('@/views/solutions/SolutionEdit')
const SolutionAdd = () => import('@/views/solutions/SolutionAdd')

const SolutionFeatureList = () => import('@/views/solutions/features/SolutionFeatureList')
const SolutionFeatureEdit = () => import('@/views/solutions/features/SolutionFeatureEdit')
const SolutionFeatureAdd = () => import('@/views/solutions/features/SolutionFeatureAdd')

const SolutionReviewList = () => import('@/views/solutions/reviews/SolutionReviewList')
const SolutionReviewEdit = () => import('@/views/solutions/reviews/SolutionReviewEdit')
const SolutionReviewAdd = () => import('@/views/solutions/reviews/SolutionReviewAdd')

const Calc = () => import('@/views/calc/Calc')

const CustomerList = () => import('@/views/customers/CustomerList')
const CustomerEdit = () => import('@/views/customers/CustomerEdit')
const CustomerCreate = () => import('@/views/customers/CustomerCreate')

const SupportList = () => import('@/views/support/SupportList')
const SupportEdit = () => import('@/views/support/SupportEdit')
const SupportAdd = () => import('@/views/support/SupportAdd')

Vue.use(Router)

let router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({y: 0}),
    routes: configRoutes()
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/pages/login')
    } else {
        next()
    }
})

export default router

function configRoutes() {
    return [
        {
            path: '/',
            name: 'Главная',
            meta: {
                requiresAuth: true
            },
            component: TheContainer,

            children: [
                {
                    path: '/categories',
                    redirect: '/categories/list',
                    name: 'Categories',
                    meta: {
                        label: 'Категории товаров'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/categories/list',
                            name: 'CategoriesList',
                            meta: {
                                label: 'Категории товаров'
                            },
                            component: CategoryList
                        },
                        {
                            path: '/categories/add',
                            name: 'CategoryAdd',
                            meta: {
                                label: 'Новая категория'
                            },
                            component: CategoryAdd
                        },
                        {
                            path: '/categories/add/:id',
                            name: 'CategoryAddChild',
                            meta: {
                                label: 'Новая категория'
                            },
                            component: CategoryAdd
                        },
                        {
                            path: '/categories/:id',
                            name: 'categoryEdit',
                            meta: {
                                label: 'Редактируем категорию'
                            },
                            component: CategoryEdit
                        },
                    ]
                },
                {
                    path: '/brands',
                    redirect: '/brands/list',
                    name: 'Бренды',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/brands/list',
                            name: 'BrandsList',
                            meta: {
                                label: 'Список брендов'
                            },
                            component: BrandList
                        },
                        {
                            path: '/brands/add',
                            name: 'BrandAdd',
                            meta: {
                                label: 'Новый бренд'
                            },
                            component: BrandAdd
                        },
                        {
                            path: '/brands/:id',
                            name: 'BrandEdit',
                            meta: {
                                label: 'Редактируем бренд'
                            },
                            component: BrandEdit
                        },
                    ]
                },
                {
                    path: '/orders',
                    redirect: '/orders/list',
                    name: 'Заказы',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/orders/list',
                            name: 'OrdersList',
                            meta: {
                                label: 'Список заказов'
                            },
                            component: OrdersList
                        },
                        {
                            path: '/orders/:id',
                            name: 'OrderEdit',
                            meta: {
                                label: 'Редактор заказа'
                            },
                            component: OrderEdit
                        },
                    ]
                },
                {
                    path: '/filters',
                    redirect: '/filters/list',
                    name: 'Фильтры',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/filters/list',
                            name: 'FiltersList',
                            meta: {
                                label: 'Доступные фильтры'
                            },
                            component: FilterList
                        },
                        {
                            path: '/filters/add',
                            name: 'FilterAdd',
                            meta: {
                                label: 'Новый фильтр'
                            },
                            component: FilterAdd
                        },
                        {
                            path: '/filters/:id',
                            name: 'FilterEdit',
                            meta: {
                                label: 'Редактируем фильтр'
                            },
                            component: FilterEdit
                        },
                    ]
                },
                {
                    path: '/products',
                    redirect: '/products/list',
                    name: 'Товары',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/products/list/:parent?',
                            name: 'ProductList',
                            meta: {
                                label: 'Список товаров'
                            },
                            component: ProductList
                        },
                        // {
                        //     path: '/products/list/:id?',
                        //     name: 'ProductListByParent',
                        //     meta: {
                        //         label: 'Список товаров'
                        //     },
                        //     component: ProductList
                        // },
                        {
                            path: '/products/moderations',
                            name: 'ModerationsList',
                            meta: {
                                label: 'Товары на модерации'
                            },
                            component: ModerationsList
                        },
                        {
                            path: '/products/add',
                            name: 'ProductAdd',
                            meta: {
                                label: 'Новый товар'
                            },
                            component: ProductAdd
                        },
                        {
                            path: '/products/:id',
                            name: 'ProductEdit',
                            meta: {
                                label: 'Редактируем товар'
                            },
                            component: ProductEdit
                        },
                    ]
                },
                {
                    path: '/slider',
                    redirect: '/slider/list',
                    name: 'slider',
                    meta: {
                        label: 'Слайды на главной'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/slider/list',
                            name: 'SliderList',
                            meta: {
                                label: 'Список слайдов'
                            },
                            component: SliderList
                        },
                        {
                            path: '/slider/add',
                            name: 'SliderAdd',
                            meta: {
                                label: 'Новый слайд'
                            },
                            component: SliderAdd
                        },
                        {
                            path: '/slider/:id',
                            name: 'SliderEdit',
                            meta: {
                                label: 'Редактируем слайд'
                            },
                            component: SliderEdit
                        },
                    ]
                },
                {
                    path: '/banners',
                    redirect: '/banner/list',
                    name: 'banners',
                    meta: {
                        label: 'Баннеры'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/banner/list',
                            name: 'BannerList',
                            meta: {
                                label: 'Список баннеров'
                            },
                            component: BannerList
                        },
                        {
                            path: '/banner/add',
                            name: 'BannerAdd',
                            meta: {
                                label: 'Новый баннер'
                            },
                            component: BannerAdd
                        },
                        {
                            path: '/banner/:id',
                            name: 'BannerEdit',
                            meta: {
                                label: 'Редактируем слайд'
                            },
                            component: BannerEdit
                        },
                    ]
                },
                {
                    path: '/navbanners',
                    redirect: '/navbanners/list',
                    name: 'navbanners',
                    meta: {
                        label: 'Реклама в навигации'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/navbanners/list',
                            name: 'NavBannerList',
                            meta: {
                                label: 'Список баннеров'
                            },
                            component: NavBannerList
                        },
                        {
                            path: '/navbanner/add',
                            name: 'NavBannerAdd',
                            meta: {
                                label: 'Новый баннер'
                            },
                            component: NavBannerAdd
                        },
                        {
                            path: '/navbanner/:id',
                            name: 'NavBannerEdit',
                            meta: {
                                label: 'Редактируем баннер'
                            },
                            component: NavBannerEdit
                        },
                    ]
                },
                {
                    path: '/deliverydays',
                    redirect: '/deliverydays/list',
                    name: 'deliverydays',
                    meta: {
                        label: 'Сроки доставки по регионам'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/deliverydays/list',
                            name: 'DeliveryDaysList',
                            meta: {
                                label: 'Сроки доставки по регионам'
                            },
                            component: DeliveryDaysList
                        },
                        {
                            path: '/deliverydays/edit/:id',
                            name: 'DeliveryDaysEdit',
                            meta: {
                                label: 'Сроки доставки по регионам'
                            },
                            component: DeliveryDaysEdit
                        },
                        {
                            path: '/deliverydays/add',
                            name: 'DeliveryDaysAdd',
                            meta: {
                                label: 'Сроки доставки по регионам'
                            },
                            component: DeliveryDaysAdd
                        },
                    ]
                },
                {
                    path: '/pricelist',
                    redirect: '/pricelist/list',
                    name: 'pricelist',
                    meta: {
                        label: 'Прайс-Листы'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/pricelist/list',
                            name: 'PriceList',
                            meta: {
                                label: 'Список прайс-листов'
                            },
                            component: PriceList
                        },
                        {
                            path: '/pricelist/edit/:id',
                            name: 'PriceListEdit',
                            meta: {
                                label: 'Изменить запись'
                            },
                            component: PriceListEdit
                        },
                        {
                            path: '/pricelist/add',
                            name: 'PriceListAdd',
                            meta: {
                                label: 'Добавить запись'
                            },
                            component: PriceListAdd
                        },
                    ]
                },
                {
                    path: '/text',
                    redirect: '/text/list',
                    name: 'Страницы',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/text/list',
                            name: 'TextList',
                            meta: {
                                label: 'Список текстовых страниц'
                            },
                            component: TextList
                        },
                        {
                            path: '/text/edit/:id',
                            name: 'TextEdit',
                            meta: {
                                label: 'Изменить страницу'
                            },
                            component: TextEdit
                        },
                        {
                            path: '/text/add',
                            name: 'TextAdd',
                            meta: {
                                label: 'Добавить страницу'
                            },
                            component: TextAdd
                        },
                    ]
                },
                {
                    path: '/news',
                    redirect: '/news/list',
                    name: 'Новости',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/news/list',
                            name: 'NewsList',
                            meta: {
                                label: 'Список новостей'
                            },
                            component: NewsList
                        },
                        {
                            path: '/news/edit/:id',
                            name: 'NewsEdit',
                            meta: {
                                label: 'Изменить новость'
                            },
                            component: NewsEdit
                        },
                        {
                            path: '/news/add',
                            name: 'NewsAdd',
                            meta: {
                                label: 'Добавить новость'
                            },
                            component: NewsAdd
                        },
                    ]
                },
                {
                    path: '/promocode',
                    redirect: '/promocode/list',
                    name: 'Промо-коды',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/promocode/list',
                            name: 'PromoCodeList',
                            meta: {
                                label: 'Список новостей'
                            },
                            component: PromoCodeList
                        },
                        {
                            path: '/promocode/edit/:id',
                            name: 'PromoCodeEdit',
                            meta: {
                                label: 'Изменить промо-код'
                            },
                            component: PromoCodeEdit
                        },
                        {
                            path: '/promocode/add',
                            name: 'PromoCodeAdd',
                            meta: {
                                label: 'Добавить промо-код'
                            },
                            component: PromoCodeAdd
                        },
                    ]
                },
                {
                    path: '/packs',
                    redirect: '/packs/list',
                    name: 'Наборы товаров',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/packs/list',
                            name: 'PackList',
                            meta: {
                                label: 'Наборы товаров'
                            },
                            component: PackList
                        },
                        {
                            path: '/packs/edit/:id',
                            name: 'PackEdit',
                            meta: {
                                label: 'Изменить набор'
                            },
                            component: PackEdit
                        },
                        {
                            path: '/packs/add',
                            name: 'PackAdd',
                            meta: {
                                label: 'Добавить набор'
                            },
                            component: PackAdd
                        },
                    ]
                },
                {
                    path: '/solutions',
                    redirect: '/solutions/list',
                    name: 'Готовые решения',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/solutions/list',
                            name: 'SolutionList',
                            meta: {
                                label: 'Готовые решения'
                            },
                            component: SolutionList
                        },
                        {
                            path: '/solutions/edit/:id',
                            name: 'SolutionEdit',
                            meta: {
                                label: 'Изменить готовое решение'
                            },
                            component: SolutionEdit
                        },
                        {
                            path: '/solutions/add',
                            name: 'SolutionAdd',
                            meta: {
                                label: 'Добавить готовое решение'
                            },
                            component: SolutionAdd
                        },
                    ]
                },
                {
                    path: '/solutions-features',
                    redirect: '/solutions-features/list',
                    name: 'Возможности для готовых решений',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/solutions-features/list',
                            name: 'SolutionFeatureList',
                            meta: {
                                label: 'Возможности для готовых решений'
                            },
                            component: SolutionFeatureList
                        },
                        {
                            path: '/solutions-features/edit/:id',
                            name: 'SolutionFeatureEdit',
                            meta: {
                                label: 'Изменить запись'
                            },
                            component: SolutionFeatureEdit
                        },
                        {
                            path: '/solutions-features/add',
                            name: 'SolutionFeatureAdd',
                            meta: {
                                label: 'Добавить запись'
                            },
                            component: SolutionFeatureAdd
                        },
                    ]
                },

                {
                    path: '/solutions-reviews',
                    redirect: '/solutions-reviews/list',
                    name: 'Отзывы для готовых решений',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/solutions-reviews/list',
                            name: 'SolutionReviewList',
                            meta: {
                                label: 'Список'
                            },
                            component: SolutionReviewList
                        },
                        {
                            path: '/solutions-reviews/edit/:id',
                            name: 'SolutionReviewEdit',
                            meta: {
                                label: 'Изменить отзыв'
                            },
                            component: SolutionReviewEdit
                        },
                        {
                            path: '/solutions-reviews/add',
                            name: 'SolutionReviewAdd',
                            meta: {
                                label: 'Добавить отзыв'
                            },
                            component: SolutionReviewAdd
                        },
                    ]
                },

                {
                    path: '/solutions-reviews',
                    redirect: '/solutions-reviews/list',
                    name: 'Калькулятор',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/calc',
                            name: 'Calc',
                            meta: {
                                label: 'Управление калькулятором'
                            },
                            component: Calc
                        },
                    ]
                },

                {
                    path: '/customers',
                    redirect: '/customers/list',
                    name: 'Пользователи',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/customers/list',
                            name: 'CustomerList',
                            meta: {
                                label: 'Список'
                            },
                            component: CustomerList
                        },
                        {
                            path: '/customers/edit/:id',
                            name: 'CustomerEdit',
                            meta: {
                                label: 'Изменить пользователя'
                            },
                            component: CustomerEdit
                        },
                        {
                            path: '/customers/add',
                            name: 'CustomerCreate',
                            meta: {
                                label: 'Новый пользователь'
                            },
                            component: CustomerCreate
                        },
                    ]
                },

                {
                    path: '/customers',
                    redirect: '/support/list',
                    name: 'Файлы',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '/support/list',
                            name: 'SupportList',
                            meta: {
                                label: 'Список'
                            },
                            component: SupportList
                        },
                        {
                            path: '/support/edit/:id',
                            name: 'SupportEdit',
                            meta: {
                                label: 'Изменить материал'
                            },
                            component: SupportEdit
                        },
                        {
                            path: '/support/add',
                            name: 'SupportAdd',
                            meta: {
                                label: 'Новый материал'
                            },
                            component: SupportAdd
                        },
                    ]
                },
            ]
        },
        {
            path: '/pages',
            redirect: '/pages/404',
            name: 'Pages',
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            children: [
                {
                    path: 'login',
                    name: 'Login',
                    component: Login
                },
            ]
        },
    ]
}

