import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

const state = {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    token: localStorage.getItem('token') || '',
    status: '',
    user: {},
    actionUrl: getBaseUrl(),
}

const mutations = {
    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
        state[variable] = value
    },
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, token, user) {
        state.status = 'success'
        state.token = token
        state.user = user
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout(state) {
        state.status = ''
        state.token = ''
    },
}

const actions = {
    login({commit}, user) {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            Axios({
                url:  this.state.actionUrl,
                data: user,
                method: 'POST',
            })
                .then(resp => {
                    console.log(resp.data);
                    if(resp.data.token && resp.data.token !== undefined && resp.data.token !== null) {
                        const token = resp.data.token
                        const user = resp.data.user
                        localStorage.setItem('token', token)
                        Axios.defaults.headers.common['Authorization'] = token
                        commit('auth_success', token, user)
                    }
                    //TODO Если возвращено недействительность токена очистить его
                    resolve(resp)
                })
                .catch(err => {
                    localStorage.removeItem('token')
                    reject(err)
                })
        })


    },
    logout({commit}) {
        return new Promise((resolve) => {
            commit('logout')
            localStorage.removeItem('token')
            delete Axios.defaults.headers.common['Authorization']
            resolve()
        })
    },
    request({commit}, data) {
        return new Promise((resolve, reject) => {
            let headers = {}
            Axios({
                url: this.state.actionUrl,
                data,
                method: 'POST',
                headers: headers,
            })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    if (err.response.status === 403) {
                        this.dispatch('logout')
                    }
                    reject(err)
                })
        })
    },
}


const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
}

function getBaseUrl() {
    let baseurl = 'https://armeks.kz/';
    if (location.hostname === 'localhost') {
        baseurl = 'https://armeks.kz/'
    }
    if (location.hostname === 'admin-armeks.kz') {
        baseurl = 'https://armeks.kz/'
    }
    if (location.hostname === 'admin.armeks.kz') {
        baseurl = 'https://armeks.kz/'
    }
    return baseurl + 'assets/components/api/action.php'
}


export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})
