import 'core-js/stable'
import 'v-markdown-editor/dist/v-markdown-editor.css';

import Vue from 'vue'
import Editor from 'v-markdown-editor'
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import CKEditor from '@ckeditor/ckeditor5-vue2';

import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import {iconsSet as icons} from './assets/icons/icons.js'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import store from './store'
import Axios from 'axios'


library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)


Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(Editor);
Vue.use(VueFileAgent);
Vue.use(CKEditor);


Vue.prototype.$log = console.log.bind(console)
Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = token
    Vue.prototype.$http.defaults.headers.common['Access-Control-Allow-Credentials'] = true
}

new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',
    components: {
        App
    }
})
